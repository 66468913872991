<template>
    <section class="talleres-ver-taller px-3">
        <navbar-admin titulo="Talleres" >
            <el-tooltip content="Editar" placement="bottom" effect="light" visible-arrow>
                <button
                    class="btn btn-square32-5d ml-2"
                    :class="permitAction('taller.editar') ? 'cr-pointer' : 'cr-not-allowed'"
                    @click="functionPermitAction('taller.editar', editarTaller)"
                >
                    <i class="icon-pencil-outline text-white" />
                </button>
            </el-tooltip>
            <el-tooltip content="Eliminar" placement="bottom-end" effect="light" visible-arrow>
                <button
                    class="btn btn-square32-5d ml-2"
                    :class="permitAction('taller.eliminar') ? 'cr-pointer' : 'cr-not-allowed'"
                    @click="functionPermitAction('taller.eliminar', eliminarTaller)"
                >
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
        </navbar-admin>
        <tabs :tabs="tabsTallerInfo" class="mb-4" />
        <router-view />
        <!-- partials  -->
        <modal-agregar-taller ref="abrirEditarTaller" @addFunctionary="showModalFunctionary" />
        <modal-agregar-funcionario ref="abrirAgregarFuncionario" @addWorkshop="showModalWorkshop"/>
        <modal-eliminar
        ref="abrirModalEliminarTaller"
        title="Eliminar taller"
        mensaje="¿Está seguro que quiere eliminar este taller?"
        @delete="deleteTaller"
        />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    components: {
        modalAgregarTaller: () => import('./partials/modalAgregarTaller.vue'),
        modalAgregarFuncionario: () => import('./partials/modalAgregarFuncionario')
    },
    data(){
        return{
            tabsTallerInfo: [
                {
                    titulo:'Información',
                    ruta:"talleres.ver.informacion",
                },
                {
                    titulo:'Documentos',
                    ruta:"talleres.ver.documentos",
                },
            ],
        }
    },
    computed:{
        ...mapGetters({
            workshop: 'talleres/ver/workshop',
        }),
        id_taller(){
            return this.$route.params.id
        },
    },
    async created(){
        this.getBreadcumbs(['talleres.main'])
        this.getSelects(['Action_get_selects_responsible_workshops', 'Action_get_selects_city_paginated'])
        await this.Action_get_workshop_info(this.id_taller)
        this.spliceBreadcumbs([
            {
                position: 1,
                breadcumb: {
                    name: this.workshop.nombre,
                    route: 'talleres.ver.informacion',
                    params: { id: this.workshop.id }
                }
            }
        ])
    },
    methods:{
        ...mapActions({
            getSelects: 'selects/selects/sync',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            Action_get_workshop_info: 'talleres/ver/Action_get_workshop_info',
            Action_delete_workshop: 'talleres/talleres/Action_delete_workshop'
        }),
        eliminarTaller(){
            this.$refs.abrirModalEliminarTaller.toggle()
        },
        editarTaller(){
            this.$refs.abrirEditarTaller.edit()
        },
        showModalFunctionary(){
            this.$refs.abrirAgregarFuncionario.toggle()
            this.$refs.abrirEditarTaller.toggle()
        },
        showModalWorkshop(){
            this.$refs.abrirEditarTaller.toggle()
            this.$refs.abrirAgregarFuncionario.toggle()
        },
        async deleteTaller(){
			await this.Action_delete_workshop(this.id_taller)
			this.$router.push({name: 'talleres.main'})
		},
    }

}
</script>

<style lang="scss" scoped>
.talleres-ver-taller{
    background: white;
}
</style>